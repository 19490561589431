@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invalid input:invalid {
  border: 1px solid #e20613;
}

.error-border {
  border: 2px solid #e20613 !important;
}

.form-label {
  @apply text-xs text-ooh-gray leading-4 mb-2 font-bold;
}

.form-label-checkbox {
  @apply text-xs text-ooh-gray leading-4 mb-3;
}

.form-primary-button {
  @apply w-full py-2 px-4 my-5 rounded-lg shadow-sm text-2xl font-extrabold text-white bg-ooh-warm-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 font-heading;
}

.form-input {
  @apply appearance-none block w-full p-3 border-2 border-ooh-line rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:border-ooh-pale-blue text-base leading-5 text-ooh-warm-gray bg-ooh-light-silver disabled:bg-ooh-gray-light;
  -webkit-text-fill-color: #564b51; /*SAFARI DISABLED INPUT OPACITY FIX*/
  opacity: 1; /*iOS SAFARI DISABLED INPUT OPACITY FIX*/
  height: 48px;
}

.form {
  @apply w-full px-4 py-12 md:px-52 lg:w-1/2 lg:px-24 lg:py-16 xl:px-44 xl:py-24;
}

.form-title {
  @apply text-center text-2xl font-extrabold text-ooh-warm-gray md:text-3xl;
}

.form-subtitle {
  @apply mt-4 mb-6 pb-0 text-base leading-6 text-ooh-warm-gray text-center font-normal;
}

.error {
  @apply text-ooh-red text-sm font-sans font-normal;
}

.verification-code-label {
  @apply flex items-center justify-center text-xs text-ooh-warm-gray font-bold;
}

.code_container {
  @apply h-ooh-code-box w-full;
}

.code_character {
  @apply max-h-14 text-2xl px-1 bg-ooh-light-silver border-2 border-ooh-line rounded-lg text-ooh-warm-gray flex items-center justify-center sm:px-3 sm:max-h-full sm:text-4xl;
}

.code_character--selected {
  @apply outline-none border-ooh-pale-blue;
}

form .vi__wrapper {
  @apply flex items-center justify-center w-full;
}

form .vi__wrapper input.vi {
  @apply h-0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply leading-ooh font-heading;
}
h1 {
  @apply text-4xl lg:text-5xl lg:leading-ooh;
}
h2 {
  @apply text-2xl lg:text-4xl lg:leading-ooh;
}
h3 {
  @apply text-xl lg:text-3xl lg:leading-ooh;
}
h4 {
  @apply text-h4 lg:leading-ooh;
  padding-bottom: 0.5em;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-xs;
}
p {
  @apply text-base;
  padding-bottom: 0.5em;
}
a {
  @apply text-base;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-28 16:11:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.spinnerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.page-loading {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.page-loading::before,
.page-loading::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
.page-loading::after {
  border-color: #ace000;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

form .react-tel-input {
  @apply font-body text-ooh-warm-gray;
}

form .react-tel-input .form-control {
  @apply form-input font-body;
  letter-spacing: 0px;
  padding-left: 70px;
}

form .react-tel-input .flag-dropdown {
  @apply border-ooh-line;
  background: 0 none;
  border-width: 0 2px 0 0;
  height: 44px;
  top: 2px;
}
form .react-tel-input .country-list .flag {
  top: 7px;
}

form .react-tel-input .flag-dropdown.phone-focused {
  @apply border-ooh-pale-blue;
}

form .react-tel-input .flag-dropdown.open .selected-flag,
form .react-tel-input .flag-dropdown.open {
  @apply rounded-l-lg bg-transparent;
}

form .react-tel-input .selected-flag:hover,
form .react-tel-input .selected-flag:focus {
  @apply bg-transparent;
}

form .react-tel-input .selected-flag {
  @apply rounded-l-lg pl-4;
  width: 60px;
}

form .react-tel-input .flag-dropdown.invalid-number {
  @apply border-ooh-red;
}

form .react-tel-input .form-control.invalid-number:focus,
form .react-tel-input .form-control.invalid-number {
  border: 2px solid #e20613;
}

.translateX0 {
  transform: translateX(0);
}
.translateX100 {
  transform: translateX(100%);
}

.locationsShelfTransition {
  transition: transform 0.5s ease-in-out;
}

.opacityTransition {
  transition: opacity 0.5s ease-in-out;
}

.datePicker .rotate-45 {
  transform: rotate(45deg);
  margin-left: 1.2rem;
}

.datePicker div[class*='mt-[1px]'] {
  margin-top: 1px;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}
